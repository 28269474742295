import { BuyCreditsOptionsProps, BuyCreditsOptionsStateProps, SelectedCreditOptionConfig } from '../../types/components/credits/BuyCreditsOptions';
import { CreditPurchaseVariant } from '../../types/helpers/credits';
import { CreditPackResponse } from '../../types/api/CreditsTypes';
import { SubscriptionDetail, PlanVariantResponse } from '../../types/api/SubscriptionTypes';
import { ApplicationState } from '../../types/state/storeTypes';
import { LicenseTypeName } from '../../types/api/LicenseTypesTypes';

import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import CreditPackSelection from './CreditPackSelection';
import CreditPlanUpgrade from './CreditPlanUpgrade';

import licenseHelpers from '../../helpers/licenseHelpers';
import creditsHelpers from '../../helpers/creditsHelpers';
import getPlanVariants from '../../hooks/getPlanVariants';

export const selectedOptionWrapperClass = 'border-a-blue';
export const unselectedOptionWrapperClass = 'border-a-dark-gray';

const BuyCreditsOptions: React.FC<BuyCreditsOptionsProps> = (props: BuyCreditsOptionsProps) => {
  const { creditsMissing, currentPlan, onOptionSelected, onValidationStatusChanged, licenseId, licenseTypes } = props;
  const { generateBuyCreditsLayoutConfig } = creditsHelpers;
  const { getLicenseNameById } = licenseHelpers;

  const licenseName = getLicenseNameById(licenseTypes, licenseId);
  const { data: planVariants } = getPlanVariants(licenseId, licenseName as LicenseTypeName);

  const [layoutConfig, setLayoutConfig] = useState<Array<CreditPurchaseVariant>>();
  const [selectedPurchaseOption, setSelectedPurchaseOption] = useState<SelectedCreditOptionConfig>();
  const [preselectedPlanOption, setPreselectedPlanOption] = useState<SelectedCreditOptionConfig>();
  const [preselectedPackOption, setPreselectedPackOption] = useState<SelectedCreditOptionConfig>();
  const [planVariantsInitialized, setPlanVariantsInitialized] = useState<boolean>(false);
  const [autoSelectDone, setAutoSelectDone] = useState<boolean>(false);

  useEffect(() => {
    if (!planVariants?.length) return;
    const layoutConfig = generateBuyCreditsLayoutConfig(creditsMissing, currentPlan, planVariants);
    setLayoutConfig(layoutConfig);
  }, [planVariants]);

  useEffect(() => {
    if (!layoutConfig || autoSelectDone) return;
    if (
      layoutConfig.find(item => item === 'creditPlan') && (
        !planVariantsInitialized ||
        !preselectedPlanOption
      ) ||
      !preselectedPackOption
    ) return;
    autoSelectFirstOption();
  }, [
    layoutConfig,
    planVariantsInitialized,
    preselectedPackOption,
    preselectedPlanOption,
  ]);

  const autoSelectFirstOption = () => {
    onToggleOptionSelected(layoutConfig[0]);
    setAutoSelectDone(true);
  }

  const onUpgradePlanSelected = (plan: PlanVariantResponse) => {
    const selectedOptionConfig: SelectedCreditOptionConfig = {
      type: 'creditPlan',
      data: plan,
    };
    setPreselectedPlanOption(selectedOptionConfig);
    if (!selectedPurchaseOption) return;
    setSelectedPurchaseOption(selectedOptionConfig);
    onOptionSelected(selectedOptionConfig);
  }

  const onCreditPackSelected = (pack: CreditPackResponse, isCustomAmount: boolean, customAmount?: number) => {
    const selectedOptionConfig: SelectedCreditOptionConfig = {
      type: 'creditPack',
      data: pack,
      isCustomCreditPack: isCustomAmount,
      customCreditPackAmount: customAmount,
    };
    setPreselectedPackOption(selectedOptionConfig);
    if (!selectedPurchaseOption) return;
    setSelectedPurchaseOption(selectedOptionConfig);
    onOptionSelected(selectedOptionConfig);
  };

  const onCreditAmountSelected = (amount: number) => {
    const updatedOptionConfig = { ...preselectedPackOption, customCreditPackAmount: amount };
    setPreselectedPackOption(updatedOptionConfig);
    if (!selectedPurchaseOption) return;
    setSelectedPurchaseOption(updatedOptionConfig);
    onOptionSelected(updatedOptionConfig);
  }

  const onToggleOptionSelected = (variantType: CreditPurchaseVariant) => {
    let selectedOptionConfig: SelectedCreditOptionConfig;
    switch (variantType) {
      case 'creditPack':
        selectedOptionConfig = preselectedPackOption;
        break;
      case 'creditPlan':
        selectedOptionConfig = preselectedPlanOption;
        break;
      default:
        break;
    }
    setSelectedPurchaseOption(selectedOptionConfig);
    onOptionSelected(selectedOptionConfig);
  }

  const renderToggle = (variantType: CreditPurchaseVariant): React.ReactElement => {
    return (
      <span className={`
          relative w-5 h-5 rounded-[50%] border-[1px] inline-block align-top mr-4 mt-2 cursor-pointer
          ${variantType === selectedPurchaseOption?.type ? 'border-a-blue' : 'border-gray-600'}
        `}
        cy-test-id={`${variantType}-toggle-button`}
        onClick={() => onToggleOptionSelected(variantType)}
      >
        {
          variantType === selectedPurchaseOption?.type &&
          <span className="absolute block w-4 h-4 rounded-[50%] bg-a-blue top-[1px] left-[1px]"></span>
        }
      </span>
    )
  };

  const renderVariantWrapper = (child: React.ReactElement, variantType: CreditPurchaseVariant): React.ReactElement => {
    return (
      <div
        className={`
          w-full px-4 pt-4 pb-7 pr-0 rounded-[5px] bg-a-dark-gray border-[2px]
          ${layoutConfig.length > 1 && variantType === selectedPurchaseOption?.type ? selectedOptionWrapperClass : unselectedOptionWrapperClass}
        `}
        cy-test-id="variant-option-wrapper"
        key={`variant-option-wrapper-${variantType}`}
      >
        {layoutConfig.length > 1 && renderToggle(variantType)}
        <div className="inline-block max-md:max-w-[86%]">
          {child}
        </div>
      </div>
    )
  }

  const renderCreditPlanUpgradeBlock = (): React.ReactElement => {
    const component = <CreditPlanUpgrade
      creditsMissing={creditsMissing}
      licenseId={licenseId}
      licenseTypes={licenseTypes}
      onUpgradePlanSelected={onUpgradePlanSelected}
      onPlanVariantsFiltered={() => setPlanVariantsInitialized(true)}
      selectedCreditPurchaseOption={selectedPurchaseOption}
    />
    return renderVariantWrapper(component, 'creditPlan');
  };

  const renderCreditPackPurchaseBlock = (): React.ReactElement => {
    const component = <CreditPackSelection
      licenseId={licenseId}
      creditsMissing={creditsMissing}
      onCreditPackSelected={onCreditPackSelected}
      onCreditAmountSelected={onCreditAmountSelected}
      onValidationStatusChanged={onValidationStatusChanged}
      selectedCreditPurchaseOption={selectedPurchaseOption}
      autoSelectFirstOption={true}
    />
    return renderVariantWrapper(component, 'creditPack');
  }

  return (
    <div cy-test-id="buy-credits-options" className="flex flex-col gap-4">
      {layoutConfig?.map(purchaseVariant => {
        switch (purchaseVariant) {
          case 'creditPack': return renderCreditPackPurchaseBlock();
          case 'creditPlan': return renderCreditPlanUpgradeBlock();
        }
      })}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState): BuyCreditsOptionsStateProps => ({
  currentPlan: state.auth.subscription as SubscriptionDetail,
});

export default connect(mapStateToProps)(BuyCreditsOptions);
