export const ANSWERS_LICENSEE = {
  INDIVIDUAL_LICENSEE: "Only me, an individual",
  STARTUP_LICENSEE: "Someone else at my organization",
  ENTERPRISE_LICENSEE: "Multiple artists at my organization",
};

export const ANSWERS_ASSETS_AVAILABLE_TO = {
  SINGLE_PERSON: "No, only I will have access to the files",
  MULTIPLE_ARTISTS: "Multiple artists at my organization"
};

export const ANSWERS_ANNUAL_REVENUE = {
  UNDER_3M: "Under $3 Million USD",
  ABOVE_3M: "At least $3 Million USD",
};

export const ANSWERS_TOTAL_ANNUAL_REVENUE = {
  USD_3_20: "$3M-$20M USD",
  USD_21_49: "$21M-$49M USD",
  USD_50_99: "$50M-$99M USD",
  OVER_100: "Over $100M USD",
};

export const flowConfigurations = [
  { type: 'base', nextStep: 1, license_id: '1' },
  {
    type: 'question',
    props: {
      questionText: 'Who will be licensed to use the files downloaded from ActionVFX?',
      options: [
        ANSWERS_LICENSEE.INDIVIDUAL_LICENSEE,
        ANSWERS_LICENSEE.STARTUP_LICENSEE,
        ANSWERS_LICENSEE.ENTERPRISE_LICENSEE
      ]
    },
    nextStep: (answer: string) => {
      if (answer === ANSWERS_LICENSEE.INDIVIDUAL_LICENSEE) return 2;
      if (answer === ANSWERS_LICENSEE.STARTUP_LICENSEE) return 3;
      if (answer === ANSWERS_LICENSEE.ENTERPRISE_LICENSEE) return 4;
    },
    license_id: (answer: string) => {
      if (answer === ANSWERS_LICENSEE.INDIVIDUAL_LICENSEE) return '1';
      if (answer === ANSWERS_LICENSEE.STARTUP_LICENSEE) return '2';
      if (answer === ANSWERS_LICENSEE.ENTERPRISE_LICENSEE) return '3';
    }
  },
  {
    type: 'question',
    props: {
      questionText:
        'Will the files downloaded from ActionVFX be accessible to more than one person through shared storage spaces or project files?',
      options: [
        ANSWERS_ASSETS_AVAILABLE_TO.MULTIPLE_ARTISTS,
        ANSWERS_ASSETS_AVAILABLE_TO.SINGLE_PERSON
      ]
    },
    nextStep: (answer: string) => ({
      type: answer === ANSWERS_ASSETS_AVAILABLE_TO.SINGLE_PERSON ? 'Individual' : 'Startup',
      step: 'licenseCheck'
    }),
    license_id: (answer: string) => {
      if (answer === ANSWERS_ASSETS_AVAILABLE_TO.SINGLE_PERSON) return '1';
      if (answer === ANSWERS_ASSETS_AVAILABLE_TO.MULTIPLE_ARTISTS) return '2';
    }
  },
  {
    type: 'question',
    props: {
      questionText: 'How much does your company make in revenue or funding per year?',
      options: [ANSWERS_ANNUAL_REVENUE.UNDER_3M, ANSWERS_ANNUAL_REVENUE.ABOVE_3M]
    },
    nextStep: (answer: string) => ({
      type: answer === ANSWERS_ANNUAL_REVENUE.UNDER_3M ? 'Startup' : 'Enterprise',
      step: 'licenseCheck'
    }),
    license_id: (answer: string) => {
      if (answer === ANSWERS_ANNUAL_REVENUE.UNDER_3M) return '2';
      if (answer === ANSWERS_ANNUAL_REVENUE.ABOVE_3M) return '3';
    }
  },
  {
    type: 'question',
    props: {
      questionText: 'How much does your company make in revenue or funding per year?',
      options: [ANSWERS_ANNUAL_REVENUE.UNDER_3M, ANSWERS_ANNUAL_REVENUE.ABOVE_3M]
    },
    nextStep: (answer: string) =>
      answer === ANSWERS_ANNUAL_REVENUE.UNDER_3M ? { type: 'Startup', step: 'licenseCheck' } : 5,
    license_id: (answer: string) => {
      if (answer === ANSWERS_ANNUAL_REVENUE.UNDER_3M) return '2';
      if (answer === ANSWERS_ANNUAL_REVENUE.ABOVE_3M) return '3';
    }
  },
  {
    type: 'question',
    props: {
      questionText: "What's your total annual revenue/funding?",
      options: [ANSWERS_TOTAL_ANNUAL_REVENUE['USD_3_20'], ANSWERS_TOTAL_ANNUAL_REVENUE['USD_21_49'], ANSWERS_TOTAL_ANNUAL_REVENUE['USD_50_99'], ANSWERS_TOTAL_ANNUAL_REVENUE.OVER_100]
    },
    nextStep: () => ({ type: 'Enterprise', step: 'licenseCheck' }),
    license_id: (answer: string) => {
      if (answer === ANSWERS_TOTAL_ANNUAL_REVENUE.USD_3_20) return '3';
      if (answer === ANSWERS_TOTAL_ANNUAL_REVENUE.USD_21_49) return '4';
      if (answer === ANSWERS_TOTAL_ANNUAL_REVENUE.USD_50_99) return '5';
      if (answer === ANSWERS_TOTAL_ANNUAL_REVENUE.OVER_100) return '6';
    }
  },
  { type: 'licenseCheck' },
  {
    type: 'agree',
    props: {
      Individual: {
        licenseType: 'Individual',
        licenseDescription:
          'This license type includes full commercial rights for 1 (one) person making less than $200K USD per year in revenue or funding. Any files licensed with this type must never be accessible to anyone other than the named licensee.',
        disqualificationList: [
          "You're a studio, agency, or other types of team comprised of more than 1 person",
          "You're a contractor working inside a studio (the studio will need their own license)"
        ]
      },
      Startup: {
        licenseType: 'Startup',
        licenseDescription:
          'This license type includes full commercial rights for 1 (one) company and covers all its artists and physical locations, as long as the total annual revenue and funding of the company is less than $3M USD.',
        disqualificationList: [
          "Your company's revenue or funding is higher than $3 Million USD per year",
          'You need your license to cover multiple companies and subsidiaries'
        ]
      },
      Enterprise: {
        licenseType: 'Enterprise',
        licenseDescription:
          'This license type includes full commercial rights for a company making at least $3M USD per year in revenue or funding. It covers all its artists, physical locations, and subsidiaries.'
      }
    },
    nextStep: 'form'
  },
  {
    type: 'form',
    nextStep: 'confirm'
  },
  {
    type: 'confirm',
    nextStep: 'end'
  }
];

export const getLicenseErrorContent = (currentLicense: string, suggestedLicense: string) => {
  return {
    current: {
      id: licenseErrorCards[currentLicense].id,
      title: currentLicense,
      descriptionList: licenseErrorCards[currentLicense].descriptionList
    },
    suggested: {
      id: licenseErrorCards[suggestedLicense].id,
      title: suggestedLicense,
      descriptionList: licenseErrorCards[suggestedLicense].descriptionList
    }
  };
};

export const licenseErrorCards = {
  Individual: {
    id: 1,
    title: 'Individual',
    descriptionList: [
      'Licensed to 1 person only',
      'Forbidden for teams & studios',
      'Under $200K per year in revenue'
    ]
  },
  Startup: {
    id: 2,
    title: 'Startup',
    descriptionList: [
      'Licensed to companies making under $3M per year',
      'Accessible to your entire team'
    ]
  },
  Enterprise: {
    id: 3,
    title: 'Enterprise',
    descriptionList: [
      'Licensed to companies making over $3M per year',
      'Accessible to your entire team'
    ]
  }
};

export const formTypeConfig = {
  individual: {
    id: 1,
    title: 'Individual License',
    description:
      'The Licensee for an "Individual" license must be the person using the purchased products, not a company.',
    formData: {
      first_name: '',
      last_name: '',
      email: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    },
    required: [
      'first_name',
      'last_name',
      'email',
      'addressLine1',
      'city',
      'state',
      'zip',
      'country'
    ]
  },
  startup: {
    id: 2,
    title: 'Startup License',
    description:
      'Use your registered business name. If not a registered business, use your legal first and last name.',
    formData: {
      company_name: '',
      first_name: '',
      last_name: '',
      email: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    },
    required: ['company_name', 'email', 'addressLine1', 'city', 'state', 'zip', 'country']
  },
  enterprise: {
    id: 3,
    title: 'Enterprise License',
    description: 'Please provide your company details for the Enterprise license.',
    formData: {
      company_name: '',
      email: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    },
    required: ['company_name', 'email', 'addressLine1', 'city', 'state', 'zip', 'country']
  }
};
