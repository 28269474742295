import { SubscriptionDetail, SubscriptionInterval, PlanVariantResponse } from '../types/api/SubscriptionTypes';
import { LicenseTypeDetail, LicenseTypeId } from '../types/api/LicenseTypesTypes';
import { User } from '../types/api/UsersTypes';

import subscriptionHelpers from './subscriptionHelpers';

const filterPlanVariants = (
  variants: Array<PlanVariantResponse>,
  selectedLicense: LicenseTypeDetail,
  interval: SubscriptionInterval,
): Array<PlanVariantResponse> => {
  return variants
        .filter(
          variant => variant.plan.name === selectedLicense.name &&
                     variant.interval === interval
        ).sort(
          (a, b) => a.recurring_credits - b.recurring_credits
        );
}

const isUpgradeOrDowngrade = (
  selectedVariant: PlanVariantResponse,
  selectedLicense: LicenseTypeDetail | { id: number },
  currentPlan: SubscriptionDetail,
): 'upgrade' | 'downgrade' | false => {
  const { isLegacy } = subscriptionHelpers;
  if (isLegacy(currentPlan)) return 'upgrade';
  const currentVariant = currentPlan.plan_variant;
  const selectedVariantLicenseId = selectedLicense.id;
  if (currentPlan.license.id < selectedVariantLicenseId) return 'upgrade';
  if (currentPlan.license.id > selectedVariantLicenseId) return 'downgrade';

  if (currentVariant.interval === 'month' && selectedVariant.interval === 'year') return 'upgrade';
  if (currentVariant.interval === 'year' && selectedVariant.interval === 'month') return 'downgrade';

  if (currentVariant.recurring_credits < selectedVariant.recurring_credits) return 'upgrade';
  if (currentVariant.recurring_credits > selectedVariant.recurring_credits) return 'downgrade';

  return false;
}

const getDowngradeTooltipText = (
  selectedVariant: PlanVariantResponse,
  currentPlan: SubscriptionDetail,
  selectedInterval: SubscriptionInterval,
): string => {
  const { recurring_credits, price } = selectedVariant;
  const { next_payment_date } = currentPlan;
  const intervalShorthand = selectedInterval === 'month' ? 'mo' : 'yr';
  const dateFormatted = new Date(next_payment_date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  return `You will be downgraded to the ${recurring_credits} credits/${intervalShorthand} ($${parseFloat(price).toFixed(2)}/${intervalShorthand}) at the end of your billing period on ${dateFormatted}.`;
}

// TODO test
const getMinimalLicenseIdForUpgrade = (user?: User, subscription?: SubscriptionDetail): LicenseTypeId => {
  if (!user) return 1;

  let minLicenseIdFromUserLicenseId: LicenseTypeId = (user.min_license_id || 1) as LicenseTypeId;
  let minLicenseIdFromUserType: LicenseTypeId;
  let minLicenseIdFromUserTeam: LicenseTypeId = 1;
  let minLicenseIdFromSubscription: LicenseTypeId;

  switch (user.user_type as any) {
    case 'IndividualUser':
    default:
      minLicenseIdFromUserType = 1; break;
    case 'BusinessUser':
    case 'BusinessStudio':
      minLicenseIdFromUserType = 2; break;
  }

  if (user.team?.licensee_profile) {
    const { completed, invalidated } = user.team?.licensee_profile;
    if (completed && !invalidated) {
      minLicenseIdFromUserTeam = user.team?.licensee_profile?.license_type.id as LicenseTypeId;
    }
  }

  const licenseIdsToFactorIn = [
    minLicenseIdFromUserLicenseId,
    minLicenseIdFromUserType,
    minLicenseIdFromUserTeam,
  ];

  const returnValue = (values: Array<LicenseTypeId>): LicenseTypeId => {
    let max = Math.max(...values);
    if (max > 3) max = 3;
    return max as LicenseTypeId;
  }

  if (!subscription) return returnValue(licenseIdsToFactorIn);

  minLicenseIdFromSubscription = subscription.license.type.id as LicenseTypeId;
  return returnValue([ ...licenseIdsToFactorIn, minLicenseIdFromSubscription ]);
}

const subscriptionUpgradeHelpers = {
  filterPlanVariants,
  isUpgradeOrDowngrade,
  getDowngradeTooltipText,
  getMinimalLicenseIdForUpgrade,
};

export default subscriptionUpgradeHelpers;
