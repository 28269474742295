import { StripeCreditBalanceBlockProps, StripeCreditBalanceBlockStateProps } from '../../types/components/payment/StripeCreditBalanceBlock';
import { StripeCustomerData } from '../../types/api/stripe/customer';
import { ApplicationState } from '../../types/state/storeTypes';
import { User } from '../../types/api/UsersTypes';

import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import stripeHelpers from '../../helpers/stripe/helpers';
import formattingHelpers from '../../helpers/formatting';

const StripeCreditBalanceBlock: React.FC<StripeCreditBalanceBlockProps> = ({
  user,
  className,
  onDataLoaded,
}) => {
  const { getStripeCustomerDetailsFor } = stripeHelpers;
  const { formatPrice } = formattingHelpers;

  const [stripeCustomerData, setStripeCustomerData] = useState<StripeCustomerData>();

  const loadStripeCustomerData = async () => {
    if (!user?.id || !user?.stripe_customer_token) return;
    const result: StripeCustomerData = await getStripeCustomerDetailsFor(user) as StripeCustomerData;
    if (!result?.id) return;
    setStripeCustomerData(result);
    if (onDataLoaded) onDataLoaded(result);
  }

  useEffect(() => {
    loadStripeCustomerData();
  }, [user])

  const getCreditBalance = (): number => {
    if (stripeCustomerData?.balance > 0) return 0;
    return stripeCustomerData?.balance * -1 / 100 || 0;
  }

  const getCreditBalanceFormatted = (): string => {
    return formatPrice(getCreditBalance());
  }

  if (!getCreditBalance()) return <></>;

  return (
    <div
      cy-test-id="stripe-credit-balance-block"
      className={`${className} flex flex-row`}
    >
      <span>Credit balance</span>
      <span className="ml-auto">${getCreditBalanceFormatted()}</span>
    </div>
  )
}

const mapStateToProps = (state: ApplicationState): StripeCreditBalanceBlockStateProps => ({
  user: state.auth.user as User,
});

export default connect(mapStateToProps)(StripeCreditBalanceBlock);
