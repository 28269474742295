import Script from 'next/script';
import { useSession } from 'next-auth/react';
import { SessionState } from '../../types/state/reducers/authReducersTypes';

import { ENV } from '../../constants/environments';
import { FB_PIXEL_ID } from '../../shared/analytics/Facebook';

function renderFacebookVerification(): React.ReactNode {
    if (ENV.appEnv !== 'production') return null;
    return <meta name="facebook-domain-verification" content={ENV.fbDomainVerification} />;
  }

function renderGoogleTagManagerHead(): React.ReactNode {
  if (ENV.appEnv !== 'production') return null;
  return <script
    async
    dangerouslySetInnerHTML={{
      __html: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NC28WPP');
      `
    }}
  />
}

function renderFlowpointCustomEventsScript(): React.ReactNode {
  return <script
    dangerouslySetInnerHTML={{
      __html: `
        window.fpDataLayer = window.fpDataLayer || [];
        flowpoint = {
          disable: () => window.flowpointDisabled = true,
          enable: () => window.flowpointDisabled = false,
        }
        'track setUserAttributes'.split(' ').forEach(m => flowpoint[m] = (e, d) => window.fpDataLayer.push({ method: m, data: [e, d] }))
      `
    }}
  />
}

export const HeaderScripts: React.FC<any> = () => {
  return (
    <>
      {renderFacebookVerification()}
      {renderGoogleTagManagerHead()}
      {renderFlowpointCustomEventsScript()}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${FB_PIXEL_ID}');
            fbq('track', 'PageView');
          `
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
            <img
              height="1"
              width="1"
              style="display:none"
              src="https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1"
            />
          `
        }}
      />
      <script src="https://script.tapfiliate.com/tapfiliate.js" type="text/javascript" async />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            (function(t,a,p){t.TapfiliateObject=a;t[a]=t[a]||function(){
            (t[a].q=t[a].q||[]).push(arguments)}})(window,'tap');
            tap('create', '${ENV.tapAccountId}', { integration: "javascript" });
            tap('detect');
          `
        }}
      />
      <script
        data-cfasync="false"
        dangerouslySetInnerHTML={{
          __html: `
            window.ju_num="${ENV.justunoId}";
            window.asset_host='//cdn.jst.ai/';
            (function(i,s,o,g,r,a,m){
              i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)};
              a=s.createElement(o),
              m=s.getElementsByTagName(o)[0];
              a.async=1;
              a.src=g;
              m.parentNode.insertBefore(a,m)
            })(window,document,'script',asset_host+'vck.js','juapp');
          `
        }}
      />
      <script async src="https://sdk.flowpoint.ai?apiKey=t_5dd47ccec58d7dd0889e98b0f55c3025&clientId=DbmuXsNvohEFcnw2filpQRzyjanMCN"></script>
    </>
  );
};

export const FooterScripts: React.FC<any> = (props) => {
  const session = useSession();
  const auth = session?.data as SessionState;
  const user = auth?.user;
  const subscription = auth?.subscription;

  return (
    <>
      {!user && (
        <Script>
          {`
            window.intercomSettings = {
              api_base: "https://api-iam.intercom.io",
              app_id: "ubaz1zbf",
              custom_launcher_selector: '#launchIntercomChat',
            };
          `}
        </Script>
      )}

      {user?.email && (
        <Script>
          {`
            window.intercomSettings = {
              api_base: "https://api-iam.intercom.io",
              app_id: "ubaz1zbf",
              name: '${user?.first_name + ' ' + user?.last_name}',
              email: '${user?.email}',
              custom_launcher_selector: '#launchIntercomChat',
              Plan: '${`${
                subscription
                  ? subscription?.plan_variant?.name + ' ' + subscription?.license?.type?.name
                  : 'Not Subscribed'
              }`}',
            };
          `}
        </Script>
      )}

      <Script defer={true}>
        {`
          (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/ubaz1zbf';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
        `}
      </Script>
    </>
  );
};
