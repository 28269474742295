import TagContainer from '../components/shared/TagContainer';
import { CheckmarkIcon } from '../modules/Icons';
import Link from 'next/link';
import { SubscriptionDetail } from '../types/api/SubscriptionTypes';

export const subscriptionHasCredits = (subscription) => {
  if (!subscription) return false;
  return subscription.current_credit_balance > 0 || subscription.plan_variant.recurring_credits > 0;
};

export const planHasFeature = (plan, feature) => {
  const planFeature = plan.plan_attributes.find((p) => p.value == feature.value);
  if (!planFeature) return false;
  return planFeature.feature;
};

export const featureValueHtml = (feature, plan) => {
  switch (feature.value) {
    case 'Automatic discount on the remaining library':
      return `${Math.round((1 - plan.subscriber_discount_scalar) * 100)}% OFF`;
    default:
      return planHasFeature(plan, feature) ? <CheckmarkIcon className="mx-auto" /> : '-';
  }
};

export const featureHtml = (feature, studio = false) => {
  switch (feature.value) {
    case '[SILVER]':
      return (
        <span className="">
          Instant access of all assets with a &nbsp;
          <Link href="/collections/free-for-subscribers?membership_tier%5Bsilver%5D=1">
            <TagContainer membershipTier={'silver'} />
          </Link>{' '}
          label (a {studio ? '$21,243' : '$7,081'} value)
        </span>
      );
    case '[GOLD]':
      return (
        <span className="">
          Instant access of all assets with a &nbsp;
          <Link href="/collections/free-for-subscribers?membership_tier%5Bgold%5D=2">
            <TagContainer membershipTier={'gold'} />
          </Link>{' '}
          label (a {studio ? '$40,686' : '$13,562'} value)
        </span>
      );
    case '[ELITE]':
      return (
        <span className="">
          Instant access of all assets with a &nbsp;
          <Link href="/collections/free-for-subscribers?membership_tier%5Belite%5D=3">
            <TagContainer membershipTier={'elite'} />{' '}
          </Link>{' '}
          label (a {studio ? '$57,960' : '$19,320'} value)
        </span>
      );
    default:
      return feature.value;
  }
};

export const orderIsSubscription = (order: any): boolean => {
  if (!order) return false;
  return !!order.subscription;
};

// TODO test
export const isAVFXPlusLegacy = (subscription?: SubscriptionDetail): boolean => {
  if (!subscription) {
    return false;
  }

  return !!subscription.is_avfx_plus;
};

export const isCreditBasedLegacy = (subscription?: SubscriptionDetail): boolean => {
  if (!subscription) {
    return false;
  }

  return !!subscription.is_credit_based_legacy;
};

export const isLegacy = (subscription?: SubscriptionDetail): boolean => {
  if (!subscription) {
    return false;
  }

  const { is_avfx_plus, is_credit_based_legacy } = subscription;
  return !!is_avfx_plus || !!is_credit_based_legacy;
};

const adjustFeatureTextItem = (subscription: SubscriptionDetail, textItem: string): string => {
  const regex = new RegExp('Get <b>[0-9]+ credits</b> per (month|year)');
  if (!textItem.match(regex)) return textItem;
  const { interval, recurring_credits } = subscription.plan_variant;
  const result = textItem
                 .replace(/[0-9]+/, recurring_credits.toString())
                 .replace(/(month|year)/, interval);
  return result;
}

const subscriptionHelpers = {
  isLegacy,
  isCreditBasedLegacy,
  isAVFXPlusLegacy,
  adjustFeatureTextItem,
};

export default subscriptionHelpers;
