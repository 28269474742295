import { Dialog } from '@headlessui/react';
import { NewExitIcon } from '../../modules/Icons';
import React from 'react';
import { useRouter } from 'next/router';
import { BasicModalProps } from '../../types/components/shared/BasicModalTypes';

const BasicModal: React.FC<BasicModalProps> = (props: BasicModalProps) => {
  const { visible, setVisible, content } = props;

  function modalBgColor(content: string) {
    if (content === 'pfl-pricing') {
      return 'bg-[#252B2D]';
    } else if (content === 'login-redirect' || content === 'download-limit') {
      return 'bg-gray-900';
    } else {
      return 'bg-[#1A1D1F]';
    }
  }

  function renderExitIcon(content) {
    if (
      content === 'pfl-pricing' ||
      content === 'download-limit' ||
      content === 'checkout' ||
      content === 'category'
    ) {
      return '';
    } else return 'hidden';
  }

 return (
    <div className={props.className}>
      <Dialog
        open={visible}
        onClose={() => {
          setVisible(false);
        }}
        className="relative z-50">
        <div className="fixed inset-0 flex items-center justify-center bg-black/60 overflow-y-auto">
          <Dialog.Panel className="overflow-x-hidden max-md:h-[100vh]">
            <div
              className={`basic-modal ${modalBgColor(content)} bg-a-grey shadow-lg drop-shadow-lg rounded-[5px] text-white
                ${content === 'category'
                  ? 'w-[90vw] max-w-[1490px] h-[80vh] max-h-[896px] overflow-y-auto px-4 md:px-10'
                  : 'max-w-[800px] w-full px-4 py-8 md:py-8 md:px-8 max-h-[100vh] overflow-y-auto overflow-x-hidden'
                } lg:mx-12`}>
              {props.children}
              <NewExitIcon
                className={`${renderExitIcon(content)} absolute top-6 right-6 cursor-pointer !stroke-[#F5F5F5] opacity-[50%] w-[18px] h-[18px] scale-125`}
                onClick={() => setVisible(false)}
              />
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default BasicModal;
