import { UpgradeSubscriptionButtonProps, UpgradeSubscriptionButtonStateProps } from '../../types/components/checkout/UpgradeSubscriptionButton';
import { StripeErrorResponse } from '../../types/api/stripe/error';
import { AxiosError } from 'axios';
import { CheckoutResponse } from '../../types/helpers/checkoutHelpers';
import { User } from '../../types/api/UsersTypes';
import { ConfirmSubscriptionOrderResponse, SubscriptionDetail } from '../../types/api/SubscriptionTypes';
import { ApplicationState } from '../../types/state/storeTypes';

import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useRouter } from 'next/router';

import subscribeHelpers from '../../helpers/pages/checkout/subscribe';
import checkoutHelpers from '../../helpers/checkoutHelpers';

import { AUTH_ACTIONS } from '../../constants/actions';
const { AUTH_ON_SUBSCRIBED } = AUTH_ACTIONS;
import { setAvailableCredits } from '../../state/actions/creditActions';

import { MainLinkButton } from '../styled/buttons/MainLinkButton';
import { Loader } from '../../components/shared/Loader';

const UpgradeSubscriptionButton: React.FC<UpgradeSubscriptionButtonProps> = ({
  user,
  currentPlan,
  selectedLicenseId,
  selectedPlanVariant,
  afterSubscriptionCallback,
  // TODO test
  selectedCardId,
  paymentMethod,
  buttonText,
  doNotRedirectAfterUpgrade,
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const router = useRouter();

  const { subscribe } = subscribeHelpers;
  const { checkoutUsingSavedCard, checkoutUsingNewCard } = checkoutHelpers;

  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const renderError = (): React.ReactElement => {
    return error && <p className="bg-red-900 text-white p-3 text-[12px]" cy-test-id="error-block">{error}</p>
  }

  const errorHandler = (result: AxiosError | StripeErrorResponse) => {
    setLoading(false);
    setError((result as AxiosError).message || (result as StripeErrorResponse).error.message);
  }

  const checkout = () => {
    switch (paymentMethod) {
      case 'card': return useNewCard();
      case 'saved_card': return useSavedCard();
      default: return;
    }
  }

  const useNewCard = async () => {
    const submitElementsResult = await elements.submit();
    if (submitElementsResult.error) {
      return;
    }
    setLoading(true);
    let addOnPriceConfig: { amount: number };
    const result = await checkoutUsingNewCard(user, elements, stripe, addOnPriceConfig);
    (result as StripeErrorResponse)?.error
       ? errorHandler(result as AxiosError | StripeErrorResponse)
       : handleSubscribe(result as CheckoutResponse);
  }

  const useSavedCard = async () => {
    setLoading(true);
    const result = await checkoutUsingSavedCard(user, stripe, selectedCardId);
    (result as StripeErrorResponse)?.error
       ? errorHandler(result as AxiosError | StripeErrorResponse)
       : handleSubscribe(result as CheckoutResponse);
  }

  const handleSubscribe = async (checkoutResult: CheckoutResponse) => {
    const { confirmSetupIntentData } = checkoutResult;
    const result = await subscribe(
      user,
      selectedPlanVariant,
      confirmSetupIntentData.setupIntent.id,
      selectedLicenseId,
      dispatch,
      currentPlan,
    );
    const subscription = (result as ConfirmSubscriptionOrderResponse).subscription;
    subscription
       ? afterSubscription()
       : errorHandler(result as AxiosError);
  }

  const afterSubscription = () => {
    if (afterSubscriptionCallback) afterSubscriptionCallback();
    // const currentCreditBalance = currentPlan.current_credit_balance;
    // dispatch({ type: AUTH_ON_SUBSCRIBED, subscription });
    // let incrementValue = selectedPlanVariant.recurring_credits;
    // dispatch(setAvailableCredits(currentCreditBalance + incrementValue));
    if (doNotRedirectAfterUpgrade) return;
    router.push('/thank-you');
  }

  return (
    <div className="w-full flex flex-col gap-2" cy-test-id="upgrade-subscription-button-wrapper">
      <MainLinkButton
        variant="green"
        onClick={checkout}
        testId="upgrade-subscription-button"
        className="w-full"
        disabled={loading}
      >
        {buttonText || 'Upgrade & Pay'}
      </MainLinkButton>
      {renderError()}
      {loading && <Loader/>}
    </div>
  );
}

const mapStateToProps = (state: ApplicationState): UpgradeSubscriptionButtonStateProps => ({
  user: state.auth.user as User,
  currentPlan: state.auth.subscription as SubscriptionDetail,
});

export default connect(mapStateToProps)(UpgradeSubscriptionButton);
