import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripeOptions } from '../../constants/stripe';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useElements } from '@stripe/react-stripe-js';
import { useStripe } from '@stripe/react-stripe-js';
import creditsHelpers from '../../helpers/creditsHelpers';
import stripeHelpers from '../../helpers/stripe/helpers';
import licenseHelpers from '../../helpers/licenseHelpers';

import { setPurchasedCreditPack, clearPurchasedCreditPack } from '../../state/actions/eventTrackingActions';

import InlineError from '../shared/InlineError';
import { MainLinkButton } from '../styled/buttons/MainLinkButton';
import UpgradeSubscriptionButton from '../checkout/UpgradeSubscriptionButton';

import { ApplicationState } from '../../types/state/storeTypes';
import {
  CardSelectionAndPaymentBlockStateProps,
  CardSelectionAndPaymentBlockProps
} from '../../types/components/checkout/CardSelectionAndPaymentBlock';
import { User } from '../../types/api/UsersTypes';
import { StripePaymentMethodsList } from '../../types/api/stripe/paymentMethodsList';
import { StripePaymentMethodData } from '../../types/api/stripe/paymentMethod';
import { StripeConfirmIntentResponse } from '../../types/api/stripe/confirmIntentResponse';
import { StripeCreatePaymentIntentResponse } from '../../types/api/stripe/createPaymentIntentResponse';
import { StripePaymentIntentConfirmData } from '../../types/api/stripe/paymentIntentConfirm';
import { ApiError } from '../../types/api/ErrorsTypes';
import { AxiosError } from 'axios';
import { CreditPackResponse } from '../../types/api/CreditsTypes';
import { SuccessMessageResponse } from '../../types/api/Http';
import { PlanVariantResponse } from '../../types/api/SubscriptionTypes';
import { LicenseTypeId } from '../../types/api/LicenseTypesTypes';

import { Loader } from '../shared/Loader';

const CardSelectionAndPaymentBlock: React.FC<CardSelectionAndPaymentBlockProps> = (
  props: CardSelectionAndPaymentBlockProps
) => {
  const {
    user,
    authenticated,
    disableSubmit,
    purchasedCreditOption,
    onPurchaseCompleted,
    // TODO test
    doNotRedirectAfterUpgrade,
  } = props;
  const { getCardsFor, saveNewCardFor, createPaymentIntent, confirmPaymentIntent } = stripeHelpers;
  const { purchaseCreditPack } = creditsHelpers;
  const { getLicenseIdByName } = licenseHelpers

  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();

  const [savedCardsList, setSavedCardsList] = useState<StripePaymentMethodsList>();
  const [selectedCardData, setSelectedCardData] = useState<StripePaymentMethodData>();
  const [error, setError] = useState<ApiError>();
  const [loading, setLoading] = useState<boolean>(false);
  const [displayAddCardForm, setDisplayAddCardForm] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      dispatch(clearPurchasedCreditPack());
    }
  }, []);

  const getUpgradePlanLicenseId = (): LicenseTypeId => {
    if (purchasedCreditOption?.type !== 'creditPlan') return;
    const selectedPlanVariant: PlanVariantResponse = purchasedCreditOption.data as PlanVariantResponse;
    const licenseName = selectedPlanVariant.license_name;
    const licenseId = getLicenseIdByName(licenseName);
    return licenseId as LicenseTypeId;
  }

  const loadCards = async () => {
    if (!user?.stripe_customer_token) return;
    try {
      const cards = (await getCardsFor(user)) as StripePaymentMethodsList;
      setSavedCardsList(cards);
      cards.data?.length ? setSelectedCardData(cards.data[0]) : setDisplayAddCardForm(true);
    } catch (e) {
      setError(e);
    }
  };

  const errorCallback = (error: AxiosError) => {
    setLoading(false);
    setError(error)
  };

  const successCallback = (result: StripeConfirmIntentResponse) => {
    if (purchasedCreditOption?.type === 'creditPack') initiateCreditPackPurchase(result.setupIntent.payment_method);
  };

  const saveCard = async () => {
    const submitElementsResult = await elements.submit();
    if (submitElementsResult.error) {
      setLoading(false);
      return;
    }
    await saveNewCardFor(user, successCallback, errorCallback, stripe, elements);
  };

  const useSavedCard = async () => {
    if (purchasedCreditOption?.type === 'creditPack') initiateCreditPackPurchase(selectedCardData.id);
  };

  const initiateCreditPackPurchase = async (payment_method_id: string) => {
    const purchasedCreditPack = purchasedCreditOption.data as CreditPackResponse;
    const { isCustomCreditPack, customCreditPackAmount } = purchasedCreditOption;
    const customAmount = isCustomCreditPack ? customCreditPackAmount : undefined;
    const price = isCustomCreditPack ? customCreditPackAmount * parseFloat(purchasedCreditPack.cost_per_credit) : parseFloat(purchasedCreditPack.price);
    const paymentIntentData = await createPaymentIntent(price, user, payment_method_id, errorCallback) as StripeCreatePaymentIntentResponse;
    let confirmedIntent: StripePaymentIntentConfirmData;
    if (paymentIntentData) confirmedIntent = await confirmPaymentIntent(paymentIntentData.paymentIntent, errorCallback) as StripePaymentIntentConfirmData;
    let creditsPurchaseResult: SuccessMessageResponse;
    if (confirmedIntent) creditsPurchaseResult = await purchaseCreditPack(
      purchasedCreditPack,
      confirmedIntent.id,
      user,
      customAmount,
      errorCallback,
    ) as SuccessMessageResponse;
    if (!creditsPurchaseResult) return;
    dispatch(setPurchasedCreditPack(purchasedCreditPack, customAmount));
    setLoading(false);
    if (onPurchaseCompleted) onPurchaseCompleted();
  }

  useEffect(() => {
    if (!authenticated) return;
    loadCards();
  }, [authenticated]);

  const onSelectedCard = (id: string) => {
    if (id === 'add-new-card') {
      toggleAddCardForm();
    } else {
      const cardData = savedCardsList.data.find((item) => item.id === id);
      setSelectedCardData(cardData);
      setDisplayAddCardForm(false);
    }
  };

  const renderSavedCardsDropdown = (): React.ReactElement => {
    return (
      <select
        cy-test-id="saved-cards-dropdown"
        className="w-full text-white pl-[10px] pr-[15px] py-[15px] rounded-[5px] border-[1px] border-[#4D5457] bg-gray-800 block mb-[12px]"
        value={selectedCardData?.id}
        onChange={(e) => onSelectedCard(e.target.value)}>
        {savedCardsList?.data?.map((item, key) => (
          <option key={key} value={item.id}>
            **** **** **** {item.card.last4}
          </option>
        ))}
        <option key={'add-new-card'} value={'add-new-card'}>
          Add New Card
        </option>
      </select>
    );
  };

  const renderAddCardForm = (): React.ReactElement => {
    if (!authenticated || !displayAddCardForm) return;
    return (
      <div cy-test-id="add-card-form" className="w-full">
        <PaymentElement />
      </div>
    );
  };

  const toggleAddCardForm = () => {
    const currentValue = displayAddCardForm;
    setDisplayAddCardForm(!currentValue);
  };

  const handleSubmit = () => {
    if (loading) return;
    setLoading(true);
    displayAddCardForm ? saveCard() : useSavedCard();
  };

  return (
    <div className="w-full" cy-test-id="cards-and-payment-block">
      {renderSavedCardsDropdown()}
      {renderAddCardForm()}
      {
        purchasedCreditOption?.type === 'creditPack' &&
        <MainLinkButton
          onClick={handleSubmit}
          variant="green"
          className="w-full my-4"
          disabled={loading || disableSubmit}
          testId="confirm-and-pay-button">
          Confirm & Pay
        </MainLinkButton>
      }
      {
        purchasedCreditOption?.type === 'creditPlan' &&
        <UpgradeSubscriptionButton
          selectedLicenseId={getUpgradePlanLicenseId()}
          selectedPlanVariant={purchasedCreditOption.data as PlanVariantResponse}
          selectedCardId={selectedCardData?.id}
          paymentMethod={displayAddCardForm ? 'card' : 'saved_card'}
          afterSubscriptionCallback={() => { onPurchaseCompleted(); }}
          buttonText="Confirm & Pay"
          doNotRedirectAfterUpgrade={doNotRedirectAfterUpgrade}
        />
      }
      <InlineError errorMessage={error?.message} />
      {loading && <Loader />}
    </div>
  );
};

const container = (props: CardSelectionAndPaymentBlockProps) => {
  const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);
  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: 'setup',
        currency: 'usd',
        appearance: stripeOptions,
        paymentMethodTypes: ['card']
      }}>
      <CardSelectionAndPaymentBlock {...props} />
    </Elements>
  );
};

const mapStateToProps = (state: ApplicationState): CardSelectionAndPaymentBlockStateProps => ({
  user: state.auth.user as User,
  authenticated: state.auth.authenticated
});

export default connect(mapStateToProps)(container);
