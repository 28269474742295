import {
  DiscordIcon,
  TwoDElementsIcon,
  TwoDElementsIconOutlined,
  TwoDElementsIconOutlinedLarge,
  TwoDElementsIconFilled,
  TwoDElementsIconFilledLarge,
  ThreeDElementsIcon,
  ThreeDElementsIconOutlined,
  ThreeDElementsIconOutlinedLarge,
  ThreeDElementsIconFilled,
  ThreeDElementsIconFilledLarge,
  VideoIcon,
  VideoIconOutlined,
  VideoIconOutlinedLarge,
  VideoIconFilled,
  VideoIconFilledLarge,
  NewFolderIcon,
  NewIcon,
  LikeIcon,
  SupportIcon,
  LightbulbIcon,
  ReportBugIcon,
  LightbulbIconOutline
} from '../modules/Icons';
import New from '../pages/users/reset/new';

export const AccountNavigation = [
  {
    text: 'My Wishlists',
    href: '/accounts/wishlists',
    icon: null
  },
  {
    text: 'My Downloads',
    href: '/accounts/downloads',
    icon: null
  },
  {
    text: 'Account Settings',
    href: '/accounts/profile',
    icon: null
  },
  {
    text: 'Manage Subscription',
    href: '/accounts/subscriptions',
    icon: null
  },
  {
    text: 'Order History',
    href: '/accounts/orders',
    icon: null
  }
];

export const AccountSupportNavigation = [
  {
    text: 'Suggest a Product',
    href: 'https://actionvfx.featurebase.app/?b=65bfb4720ed972a2431dad35',
    icon: <LightbulbIconOutline className={`inline-block stroke-inherit fill-none`} />
  },
  {
    text: 'Report a Bug',
    href: 'https://actionvfx.featurebase.app/?b=65bfdd430ed972a24368d358',
    icon: <ReportBugIcon className={`inline-block stroke-inherit`} />
  }
];

export const TopNavigationAssets = [
  {
    text: '2D Elements',
    description: 'Stock Footage and textures for VFX',
    href: '/products',
    api: '/collections?library_id=1&',
    icon: <TwoDElementsIcon className={`inline-block fill-inherit`} />,
    iconOutlined: <TwoDElementsIconOutlined className={`inline-block fill-inherit`} />,
    iconOutlinedLarge: <TwoDElementsIconOutlinedLarge className={`inline-block`} />,
    iconFilled: <TwoDElementsIconFilled className={`inline-block fill-inherit`} />,
    iconFilledLarge: <TwoDElementsIconFilledLarge className={`inline-block fill-inherit`} />,
    library: 'library2d',
    libraryId: 1,
    children: [
      {
        text: 'Top Categories',
        href: '/products',
        icon: <NewFolderIcon className={`inline-block fill-inherit`} />
      },
      {
        text: 'Most Popular',
        href: '/collections/free-for-subscribers',
        icon: <LikeIcon className={`inline-block fill-inherit`} />
      },
      // {
      //   text: 'New Releases',
      //   href: '/product',
      //   icon: <NewIcon className={`inline-block fill-inherit`} />
      // }
    ]
  },
  {
    text: '3D Assets',
    description: '3D Models, VDBs, and more assets',
    href: '/3d',
    api: '/collections?library_id=2&',
    icon: <ThreeDElementsIcon className={`inline-block fill-inherit`} />,
    iconOutlined: <ThreeDElementsIconOutlined className={`inline-block fill-inherit`} />,
    iconOutlinedLarge: <ThreeDElementsIconOutlinedLarge className={`inline-block`} />,
    iconFilled: <ThreeDElementsIconFilled className={`inline-block fill-inherit`} />,
    iconFilledLarge: <ThreeDElementsIconFilledLarge className={`inline-block fill-inherit`} />,
    library: 'library3d',
    libraryId: 3,

    children: [
      {
        text: 'Top Categories',
        href: '/products',
        icon: <NewFolderIcon className={`inline-block fill-inherit`} />
      },
      {
        text: 'Most Popular',
        href: '/blog?category=tutorials',
        icon: <LikeIcon className={`inline-block fill-inherit`} />
      },
      {
        text: 'New Releases',
        href: '/blog',
        icon: <NewIcon className={`inline-block fill-inherit`} />
      }
    ]
  },
  {
    text: 'Practice Footage',
    description: 'Learn VFX with our free plates',
    href: '/practice-footage',
    api: '/scenes&',
    icon: <VideoIcon className={`inline-block fill-inherit`} />,
    iconOutlined: <VideoIconOutlined className={`inline-block fill-inherit`} />,
    iconOutlinedLarge: <VideoIconOutlinedLarge className={`inline-block`} />,
    iconFilled: <VideoIconFilled className={`inline-block fill-inherit`} />,
    iconFilledLarge: <VideoIconFilledLarge className={`inline-block fill-inherit`} />,
    library: 'libraryPfl',
    libraryId: 2,
    children: [
      {
        test: 'Top Categories',
        href: '/products',
        icon: <NewFolderIcon className={`inline-block fill-inherit`} />
      },
      {
        test: 'Most Popular',
        href: '/collections/free-for-subscribers',
        icon: <LikeIcon className={`inline-block fill-inherit`} />
      },
      {
        test: 'New Releases',
        href: '/product',
        icon: <NewIcon className={`inline-block fill-inherit`} />
      }
    ]
  }
];

export const TopNavigationLearn = [
  {
    text: 'Learn',
    href: null,
    icon: null,
    children: [
      {
        text: 'Practice Footage',
        href: '/practice-footage',
        icon: null
      },
      {
        text: 'Tutorials',
        href: '/blog?category=tutorials',
        icon: null
      },
      {
        text: 'Blog',
        href: '/blog',
        icon: null
      }
    ]
  },
  {
    text: 'Community',
    href: null,
    icon: null,
    children: [
      {
        text: 'Join our Discord',
        href: 'https://discord.gg/SEeuP5Mtku',
        icon: <DiscordIcon className={`inline-block fill-inherit`} />
      },
      {
        text: 'Request a Product',
        href: 'https://actionvfx.featurebase.app/',
        icon: null
      },
      {
        text: 'Testimonials',
        href: '/testimonials',
        icon: null
      }
    ]
  }
];
