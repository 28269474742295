import React, { useState, useRef, useEffect } from 'react';
import { SearchIcon, DownChevronIcon } from '../../modules/Icons';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { ENV } from '../../constants/environments';
import { useRouter } from 'next/navigation';
import debounce from 'lodash.debounce';
import { highlightKeywords } from '../../helpers/shared';
import { TopNavigationAssets } from '../../staticData/Navigation';
import SendSearchAnalyticsPlugin from '../user-interest-analytics/SendSearchAnalyticsPlugin';
import CollectSearchResultsPlugin from '../user-interest-analytics/CollectSearchResultsPlugin';
import { SearchType } from '../../types/api/UserInterestAnalyticsTypes';
import { CollectSearchResultsPluginProps } from '../../types/components/user-interest-analytics/CollectSearchResultsPlugin';
import { SearchMode } from '../../types/components/user-interest-analytics/SendSearchAnalyticsPlugin';
import { ProductSearchResults, ProductSearchResult, ProductSearchResultType } from '../../types/api/ProductsIndexTypes';
import { GenericResponse } from '../../types/api/Http';

const DEBOUNCE_MS = 750;

export const ProductSearch: React.FC<any> = (props) => {
  const {
    containerClass,
    className,
    placeholder,
    chevronClass,
    searchIconClass,
    dropdownAdjustments,
    homePage,
    searchResultsDropdown,
    resultsOverlayClass,
    setSelectedLibraryId,
    selectedLibraryId,
    selectedItem,
    setSelectedItem
  } = props;

  const getSearchType = (libraryId: number): SearchType => {
    const searchPosition = homePage ? 'homepage' : 'navbar';
    switch (libraryId) {
      case 1:
        return `${searchPosition}-2d`;
      case 2:
        return `${searchPosition}-pfl`;
      case 3:
        return `${searchPosition}-3d`;
      default:
        return;
    }
  }

  const getAnalyticsPluginsMode = (): SearchMode => {
    return homePage ? 'homepageDropdownSearch' : 'topNavSearch';
  }

  const getCollectSearchResultsPluginPropsFor = (result: ProductSearchResult): CollectSearchResultsPluginProps => {
    let libraryId: '2d' | '3d' | 'pfl';
    let collectionProp: ProductSearchResult;
    let categoryProp: ProductSearchResult;
    let productProp: ProductSearchResult;
    switch (result.type) {
      case 'Collection':
        collectionProp = result;
        break;
      case 'Category':
        categoryProp = result;
        break;
      default:
        break;
    }
    switch (selectedLibraryId) {
      case 1:
        libraryId = '2d';
        break;
      case 2:
        libraryId = 'pfl';
        break;
      case 3:
        libraryId = '3d';
        break;
      default:
        break;
    };
    return {
      libraryId,
      mode: getAnalyticsPluginsMode(),
      collection: collectionProp,
      category: categoryProp,
      product: productProp,
    };
  }

  const [searchResults, setSearchResults] = useState<ProductSearchResults>({});
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);
  const searchResultsRef = useRef(null);
  const searchTermRef = useRef('');
  const searchTypeRef = useRef<SearchType>(getSearchType(selectedLibraryId));
  const router = useRouter();
  const apiUrl: string = `${ENV.api.baseURL}`;

  const handleMouseEnter = (index) => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleSelectItem = (item, e) => {
    const { libraryId } = item;
    setSelectedItem(item);
    setIsDropdownOpen(false);
    setSelectedLibraryId(libraryId);
    e.stopPropagation();
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  const closeSearchResults = (e) => {
    if (searchResultsRef.current && !searchResultsRef.current.contains(e.target)) {
      setShowSearchResults(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('click', closeDropdown);
    } else {
      document.removeEventListener('click', closeDropdown);
    }
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, [isDropdownOpen]);

  const { register, getValues } = useForm({
    defaultValues: {
      search: ''
    }
  });

  const handleSearch = debounce(async (e) => {
    if (getValues('search') == '') {
      setSearchResults({});
      setShowSearchResults(false);
      return;
    }

    if (e.key === 'Enter') {
      const url = new URLSearchParams({ search: getValues('search') }).toString();
      router.push(selectedItem.href + '?' + url);
      return false;
    }

    if (e.type === 'click') {
      const url = new URLSearchParams({ search: getValues('search') }).toString();
      router.push(selectedItem.href + '?' + url);
      return false;
    }

    const url = `${apiUrl}/searches?cq=${getValues('search')}&from_searchbox=true&library_id=${
      selectedLibraryId || 1
    }`;
    searchTypeRef.current = getSearchType(selectedLibraryId);
    const res: GenericResponse<ProductSearchResults> = await axios.get(url);
    // use search term ref to sync search term and results tracking
    searchTermRef.current = getValues('search');
    setSearchResults(res.data);
    setShowSearchResults(true);
  }, DEBOUNCE_MS);

  const resultCount = (): number => {
    return Object.values(searchResults).reduce((acc, val: Array<ProductSearchResult>) => acc + val.length, 0);
  };

  const resultUrl = (result: ProductSearchResult): string => {
    switch (result.type) {
      case 'Collection':
        return `/collections/${result.path}`;
      case 'Category':
        return `/collections/${result.path}/category`;
      case 'Keyword':
        return `/products/${result.path}`;
    }
  };

  return (
    <div className="w-full relative">
      <div data-testid="productSearchingContainer">
        <div
          className={
            containerClass ||
            'rounded-[5px] flex items-center px-2 bg-gray-800 text-[14px] font-[400px] leading-24 md:min-w-[400px]'
          }
        >
          <div
            className={`hidden sm:flex items-center h-full ${
              searchIconClass ? 'border-r border-r-zinc-300' : 'border-r-[.2px] border-r-zinc-700'
            } mr-4 `}
            onClick={(e) => (toggleDropdown(e), setShowSearchResults(false))}
          >
            <ul className={`flex p-[.5px] flex-col rounded-[5px] cursor-pointer`}>
              <li>
                <div className="">
                  <div
                    className={`${
                      homePage ? 'text-black' : 'text-white ml-2'
                    } border-[#FFFFFF3D] text-[16px] font-[400px] whitespace-nowrap flex items-center gap-3 py-2 2xl:mr-4 w-[170px]`}
                  >
                    {homePage ? selectedItem?.iconFilled : selectedItem?.icon}
                    {selectedItem?.text}{' '}
                  </div>

                  {/* Dropdown Menu */}
                  <div className="flex">
                    <ul
                      ref={dropdownRef}
                      className={`${
                        isDropdownOpen ? 'flex' : 'hidden'
                      } text-white border-[#FFFFFF3D] bg-a-dark-gray absolute mt-1 flex-col px-4 py-2 rounded-[4px] drop-shadow-md left-0 ${dropdownAdjustments}`}
                      style={{ borderWidth: '0.5px' }}
                    >
                      {TopNavigationAssets.map((item, index) => (
                        <li
                          key={index}
                          onClick={(e) => handleSelectItem(item, e)}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div
                            className={`flex gap-2.5 items-center hover:text-a-blue font-light whitespace-nowrap cursor-pointer flex items-center py-2`}
                          >
                            {hoveredItem === index ? item.icon : item.iconOutlined}
                            {item.text}{' '}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </li>
            </ul>

            {/* Chevron Icon */}
            {TopNavigationAssets.length > 0 ? (
              <DownChevronIcon className={`flex ${chevronClass} inline cursor-pointer mr-4`} />
            ) : null}
          </div>

          {searchIconClass ? (
            <>
              <input
                className={className || 'pl-2 w-full bg-transparent text-sm outline-0 text-white'}
                {...register('search')}
                id={props.customId}
                type="text"
                placeholder={placeholder || 'Type keywords to search'}
                autoFocus={true}
                onKeyUp={handleSearch}
              />
              <div
                onClick={handleSearch}
                className="bg-[#00A1E0] hover:bg-[#24C1FF] px-[14px] py-[11px] rounded-[5px] mr-[-14px]"
              >
                <SearchIcon className="inline w-[24px] h-[24px] cursor-pointer stroke-white" />
              </div>
            </>
          ) : (
            <>
              <SearchIcon
                className={`inline w-[14px] h-[14px] sm:w-[24px] sm:h-[24px] stroke-white`}
              />
              <input
                className={
                  className || 'pl-2 w-full h-[30px] bg-transparent text-sm outline-0 text-white'
                }
                {...register('search')}
                id={props.customId}
                type="text"
                placeholder={placeholder || 'Type keywords to search'}
                autoFocus={true}
                onKeyUp={handleSearch}
              />
            </>
          )}
        </div>

        {showSearchResults && resultCount() >= 0 && (
          <>
            <div
              className={`overlay bg-black left-0 fixed bg-opacity-40 ${resultsOverlayClass}`}
              onClick={() => setShowSearchResults(false)}
            ></div>
            {/* do not remove keys for results divs, they make sure the triggering works for search tracking to register a new search*/}
            <div
              className={`absolute ${searchResultsDropdown} left-0 right-0 results-container bg-gray-900 rounded-b-[5px] text-white pb-5 shadow-lg drop-shadow-md mt-[2px]`}
              key={searchTermRef.current}
            >
              {/* the analytics plguin components have to be within the results dropdown b/c it has to re-render to register a new search*/}
              <SendSearchAnalyticsPlugin
                mode={getAnalyticsPluginsMode()}
                searchTerm={searchTermRef.current}
                search_type={searchTypeRef.current}
              />
              <div>
                {Object.keys(searchResults).map((bucket: ProductSearchResultType) => (
                  <div key={bucket}>
                    {searchResults[bucket].length > 0 && (
                      <div className="uppercase px-5 pb-1 pt-4 text-[12px] text-gray-600">
                        {bucket === ('keywords' as ProductSearchResultType) ? 'Search Suggestions' : bucket}
                      </div>
                    )}
                    {searchResults[bucket].map((result: ProductSearchResult, k: number) => (
                      <a
                        key={`${searchTermRef.current}-${k}`}
                        className="px-5 text-[16px] flex gap-3 py-2 items-center hover:bg-gray-800"
                        href={resultUrl(result)}
                      >
                        {result.thumbnail && (
                          <img
                            src={ENV.uploadsUrl + result.thumbnail}
                            className="w-16 border border-gray-800"
                          />
                        )}

                        {bucket === ('keywords' as ProductSearchResultType) && (
                          <SearchIcon className="inline stroke-gray-600 w-4" />
                        )}
                        <span
                          dangerouslySetInnerHTML={{
                            __html: highlightKeywords(getValues('search'), result.value)
                          }}
                        ></span>
                        {bucket !== ('keywords' as ProductSearchResultType) && (
                          <CollectSearchResultsPlugin
                            {...getCollectSearchResultsPluginPropsFor(result)}
                          />
                        )}
                      </a>
                    ))}
                  </div>
                ))}
              </div>

              <p className="text-gray-600 px-5 pt-4 text-[14px] border-t border-gray-800 mt-4">
                Click <span className="text-gray-500 font-bold">Enter</span> for more search
                results.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
