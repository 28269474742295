export const getFirstPaymentMethodByCustomer = async (stripe, customer) => {
  let paymentMethods: any = [];
  if (customer) {
    if ((customer as any).default_source) {
      let source;
      try {
        source = await stripe.customers.retrievePaymentMethod(
          customer.id,
          (customer as any).default_source
        );
        paymentMethods = {
          data: [
            await stripe.customers.retrievePaymentMethod(
              customer.id,
              (customer as any).default_source
            )
          ]
        };
      } catch (err) {
        paymentMethods = await stripe.customers.listPaymentMethods(customer.id, { type: 'card' });
      }
    } else {
      paymentMethods = await stripe.customers.listPaymentMethods(customer.id, { type: 'card' });
    }
    return paymentMethods.data[0];
  }
};

const stripeHelpers = {
  getFirstPaymentMethodByCustomer,
};

export default stripeHelpers;
