import { ENV } from '../../../constants/environments';
import { AUTH_ACTIONS } from '../../../constants/actions';
const { AUTH_ON_SUBSCRIBED, AUTH_USER_INFO_OK } = AUTH_ACTIONS;
import { setAvailableCredits } from '../../../state/actions/creditActions';

import subscriptionUpgradeHelpers from '../../subscriptionUpgradeHelpers';
import userHelpers from '../../user';

import axios, { AxiosError, AxiosResponse } from 'axios';
import errorRedirectHandler from '../../../helpers/errorRedirectHandler';
import Stripe from 'stripe';
import store from '../../../state/store';

type Store = typeof store;
type Dispatch = Store['dispatch'];

export const stripe = new Stripe(process.env.STRIPE_API_KEY, { apiVersion: '2022-11-15' });

import { GetServerSidePropsContext } from 'next';
import { SessionState } from '../../../types/state/reducers/authReducersTypes';
import { SubscribePageServerSideProps } from '../../../types/pages/checkout/subscribe';
import { RedirectConfigProps } from '../../../types/pages/generic/redirectConfig';
import { GenericResponse } from '../../../types/api/Http';
import {
  ConfirmSubscriptionOrderResponse,
  SubscriptionDetail,
  CreditPlanConfig,
  CreateSubscriptionPayload,
  PlanVariantResponse,
} from '../../../types/api/SubscriptionTypes';
import {
  PurchaseCreditPackPayload,
  PurchaseCustomCreditPackPayload,
} from '../../../types/api/CreditsTypes';
import { CheckoutAuthServerSideProps } from '../../../types/pages/checkout/subscribe/id';
import { ServerSideProps } from '../../../types/pages/generic/serverSideProps';
import { StripePaymentMethodsList } from '../../../types/api/stripe/paymentMethodsList';
import { User } from '../../../types/api/UsersTypes';
import { SelectedCreditOptionConfig } from '../../../types/components/credits/BuyCreditsOptions';
import { LicenseId } from '../../../types/api/LicenseTypesTypes';
import { LicenseTypeId } from '../../../types/api/LicenseTypesTypes';

const apiUrl: string = `${ENV.api.baseURL}`;

export const checkShouldRedirectToFallback = (ctx: GetServerSidePropsContext): boolean => {
  const { discount_code_id, setup_intent, redirect_status } = ctx.query;
  return !discount_code_id && (!setup_intent || redirect_status === 'failed');
}

export const fetchSubscriptionConfirmProps = async (
  ctx: GetServerSidePropsContext,
  auth: SessionState,
) => {
  const apiUrl: string = `${ENV.api.baseURL}`;

  try {
    const confirmRes: GenericResponse<ConfirmSubscriptionOrderResponse> = await axios.post(
      apiUrl + '/subscriptions',
      {
        setup_intent_id: ctx.query.setup_intent,
        plan_variant_id: ctx.query.plan_variant_id,
        license_id: ctx.query.license_id,
        discount_code_id: ctx.query.discount_code_id,
        redemption_code: ctx.query.redemption_code
      },
      {
        headers: {
          authorization: auth.user.token
        }
      }
    );

    const props: SubscribePageServerSideProps = {
      subscription: confirmRes?.data?.subscription || {} as SubscriptionDetail,
      orderTotal: confirmRes?.data?.orderTotal || 0,
      confirmation: true
    };

    return { props };
  } catch (e) {
    const fallbackRedirectLocation = ctx.req.headers.referer || '/';
    const redirectProps: RedirectConfigProps = errorRedirectHandler(e, `${fallbackRedirectLocation || '/'}?error=${e?.response?.data?.error || 'confirm_error'}`);
    return redirectProps;
  }
}

const fetchSubscriptionCheckoutServerSideProps = async (
  auth: SessionState,
  ctx: GetServerSidePropsContext,
): Promise<RedirectConfigProps | ServerSideProps<CheckoutAuthServerSideProps>> => {
  const { params, query } = ctx;
  const { license_id } = query;
  const { getMinimalLicenseIdForUpgrade } =  subscriptionUpgradeHelpers;
  let selectedPlan: CreditPlanConfig;

  const user = auth?.user;
  const subscription = auth?.subscription;
  const plan_id = params.id;

  const licenseId: LicenseTypeId = parseInt(license_id as string) as LicenseTypeId;
  const minimalAllowedLicenseId = getMinimalLicenseIdForUpgrade(user as User, subscription) as LicenseId;

  if (
    licenseId < minimalAllowedLicenseId ||
    subscription && subscription.plan_variant.id === parseInt(plan_id as string)
  ) {
    const redirectProps: RedirectConfigProps = {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
    return redirectProps;
  }

  try {
    const plan_url = `${apiUrl}/plan_variants/${plan_id}`;
    const selectedPlanResponse = await axios.get(plan_url);
    selectedPlan = selectedPlanResponse.data;
  } catch (e) {
    const redirectProps: RedirectConfigProps = errorRedirectHandler(e);
    return redirectProps;
  }

  if (!user || !user?.stripe_customer_token) {
    const props: CheckoutAuthServerSideProps = { selectedPlan };
    return { props };
  }
  try {
    const stripePaymentMethods = await stripe.customers.listPaymentMethods(auth.user.stripe_customer_token) as any as StripePaymentMethodsList;
    const props: CheckoutAuthServerSideProps = { stripePaymentMethods, selectedPlan };
    return { props };
  } catch (e) {
    const redirectProps: RedirectConfigProps = errorRedirectHandler(e);
    return redirectProps;
  }
}

// TODO test
const afterSubscribe = async (
  user: User,
  selectedPlan: CreditPlanConfig | PlanVariantResponse,
  newSubscription: SubscriptionDetail,
  dispatch: Dispatch,
  selectedLicenseId: LicenseId,
  previousSubscription?: SubscriptionDetail,
  addOn?: SelectedCreditOptionConfig
) => {
  const updatedUserInfo = await userHelpers.loadUserInfo(user);
  dispatch({
    type: AUTH_USER_INFO_OK,
    payload: {
      user: { ...updatedUserInfo.user, token: user.token },
      subscription: previousSubscription || null,
      authenticated: true,
    }
  });
  const currentCreditBalance = previousSubscription?.current_credit_balance || 0;
  const { isUpgradeOrDowngrade } = subscriptionUpgradeHelpers;
  if (
    previousSubscription &&
    isUpgradeOrDowngrade(
      selectedPlan as PlanVariantResponse,
      { id: selectedLicenseId},
      previousSubscription) === 'downgrade'
    ) return;
  dispatch({ type: AUTH_ON_SUBSCRIBED, subscription: newSubscription });
  let incrementValue = newSubscription.plan_variant.recurring_credits;
  if (addOn) {
    const { isCustomCreditPack, customCreditPackAmount, data } = addOn;
    const amount = isCustomCreditPack ? customCreditPackAmount : data.recurring_credits;
    incrementValue += amount;
  }
  dispatch(setAvailableCredits(currentCreditBalance + incrementValue));
}

const subscribe = async (
  user: User,
  planVariant: CreditPlanConfig | PlanVariantResponse,
  setup_intent_id: string,
  selectedLicenseId: LicenseId,
  dispatch: Dispatch,
  currentPlan?: SubscriptionDetail,
  addOn?: SelectedCreditOptionConfig,
  payment_intent_id?: string,
): Promise<ConfirmSubscriptionOrderResponse | AxiosError> => {
  const url = `${apiUrl}/subscriptions`;
  const payload: CreateSubscriptionPayload = {
    license_id: selectedLicenseId.toString(),
    plan_variant_id: planVariant.id.toString(),
    setup_intent_id,
  };
  if (addOn) {
    const { customCreditPackAmount, data: creditPack, isCustomCreditPack } = addOn;
    const credit_pack_variant: PurchaseCreditPackPayload | PurchaseCustomCreditPackPayload =
    isCustomCreditPack ?
      {
        is_custom: true,
        recurring_credits: customCreditPackAmount,
        payment_intent: payment_intent_id,
        // plan_id: planVariant.plan.id,
      } :
      {
        credit_pack_variant_id: creditPack.id.toString(),
        payment_intent: payment_intent_id,
      };
    payload.credit_pack_variant = credit_pack_variant;
  }
  try {
    const result = await axios.post(url, payload, { headers: { authorization: user.token }}) as AxiosResponse<GenericResponse<ConfirmSubscriptionOrderResponse>>;
    await subscribeHelpers.afterSubscribe(
      user,
      planVariant,
      result.data.data.subscription,
      dispatch,
      selectedLicenseId,
      currentPlan,
      addOn
    );
    return result.data.data;
  } catch (e) {
    return e;
  }
}

const subscribeHelpers = {
  subscribe,
  fetchSubscriptionCheckoutServerSideProps,
  afterSubscribe,
}

export default subscribeHelpers;
