import { useState, useCallback, useEffect } from 'react';
import { SubscriptionDetail } from '../types/api/SubscriptionTypes';
import { User } from '../types/api/UsersTypes';
import {
  flowConfigurations as _flowConfigurations,
  licenseErrorCards,
  ANSWERS_LICENSEE,
  ANSWERS_ASSETS_AVAILABLE_TO,
} from '../components/license-confirmation-modals/LicenseConfigurations';
import { licenseTypesData } from '../staticData/Licenses';
import { normalizeLicenseType, checkFreeUser } from '../helpers/licenseUtils';
import licenseHelpers from '../helpers/licenseHelpers';
import subscriptionUpgradeHelpers from '../helpers/subscriptionUpgradeHelpers';

export const useLicenseFlow = (subscription: SubscriptionDetail | null, onClose: () => void, user: User) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [showLicenseError, setShowLicenseError] = useState(false);
  const [currentLicenseType, setCurrentLicenseType] = useState<string>('');
  const [suggestedLicenseType, setSuggestedLicenseType] = useState<string>('');
  const [stepHistory, setStepHistory] = useState<number[]>([0]);
  const [licenseId, setLicenseId] = useState<string>('1');
  const { getLicenseNameById } = licenseHelpers;

  const filterConfigurations = () => {
    const { getMinimalLicenseIdForUpgrade } = subscriptionUpgradeHelpers;
    const licenseId = getMinimalLicenseIdForUpgrade(user, subscription);
    const flowConfigurations = _flowConfigurations;
    switch (licenseId) {
      default:
      case 1:
        flowConfigurations[1].props.options = [
          ANSWERS_LICENSEE.INDIVIDUAL_LICENSEE,
          ANSWERS_LICENSEE.STARTUP_LICENSEE,
          ANSWERS_LICENSEE.ENTERPRISE_LICENSEE,
        ];
        break;
      case 2:
        flowConfigurations[1].props.options = [
          ANSWERS_LICENSEE.STARTUP_LICENSEE,
          ANSWERS_LICENSEE.ENTERPRISE_LICENSEE,
        ];
        break;
      case 3:
        flowConfigurations[1].props.options = [
          ANSWERS_LICENSEE.ENTERPRISE_LICENSEE,
        ];
        break;
    }
    if (licenseId > 1)
      flowConfigurations[2].props.options = [ANSWERS_ASSETS_AVAILABLE_TO.MULTIPLE_ARTISTS];
    return flowConfigurations;
  }

  const flowConfigurations = filterConfigurations();

  useEffect(() => {
    const { getMinimalLicenseIdForUpgrade } = subscriptionUpgradeHelpers;
    const licenseId = getMinimalLicenseIdForUpgrade(user, subscription);
    const licenseName = getLicenseNameById(licenseTypesData, licenseId);
    setCurrentLicenseType(licenseName);
  }, [subscription]);


  const determineLicenseId = useCallback((currentConfig, answer) => {
    if (typeof currentConfig.license_id === 'function') {
      return currentConfig.license_id(answer);
    }
    if (currentConfig.license_id !== undefined) {
      return currentConfig.license_id;
    }
    return licenseId;
  }, [licenseId]);

  const handleNext = useCallback(() => {
    const currentConfig = flowConfigurations[currentStep];
    if (!currentConfig) return;

    const answer = formData[`question${currentStep}`];
    const nextStep = determineNextStep(currentConfig, answer);
    if (nextStep === null) return;

    const newLicenseId = determineLicenseId(currentConfig, answer);
    setLicenseId(newLicenseId.toString());

    if (isLicenseCheckStep(nextStep)) {
      handleLicenseCheck(nextStep);
      return;
    }

    const newStep = calculateNewStep(nextStep);
    if (newStep === null) return;

    if (newStep >= flowConfigurations.length) {
      onClose();
      return;
    }

    updateStep(newStep);
  }, [currentStep, formData, currentLicenseType, onClose, determineLicenseId]);

  const determineNextStep = (currentConfig, answer) => {
    if (currentConfig.type === 'base') {
      return currentStep + 1;
    }
    if (typeof currentConfig.nextStep === 'function') {
      return currentConfig.nextStep(answer);
    }
    if (!!currentConfig.nextStep) {
      return currentConfig.nextStep;
    }
    return currentStep + 1;
  };

  const isLicenseCheckStep = (step) =>
    typeof step === 'object' && step.step === 'licenseCheck';

  const handleLicenseCheck = (step) => {
    const normalizedSuggestedType = normalizeLicenseType(step.type);
    setSuggestedLicenseType(normalizedSuggestedType);

    if (normalizedSuggestedType !== currentLicenseType) {
      if (!subscription && checkFreeUser(user)) {
        navigateToAgreeStep();
      } else {
        setShowLicenseError(true);
      }
    } else {
      navigateToAgreeStep();
    }
  };

  const navigateToAgreeStep = () => {
    const agreeIndex = flowConfigurations.findIndex((config) => config.type === 'agree');
    if (agreeIndex !== -1) {
      updateStep(agreeIndex);
    }
  };

  const calculateNewStep = (nextStep) => {
    if (typeof nextStep === 'number') {
      return nextStep;
    }
    if (typeof nextStep === 'string') {
      return flowConfigurations.findIndex((config) => config.type === nextStep);
    }
    return null;
  };

  const updateStep = (newStep) => {
    setCurrentStep(newStep);
    setStepHistory((prev) => [...prev, newStep]);
  };

  const handleBack = useCallback(() => {
    if (showLicenseError) {
      setShowLicenseError(false);
    } else if (stepHistory.length > 1) {
      const newHistory = [...stepHistory];
      newHistory.pop();
      const previousStep = newHistory[newHistory.length - 1];
      setStepHistory(newHistory);
      setCurrentStep(previousStep);
    }
  }, [currentStep, showLicenseError, stepHistory]);

  const handleChange = useCallback((name: string, value: string) => {
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  return {
    currentStep,
    showLicenseError,
    flowState: {
      currentLicenseType,
      suggestedLicenseType,
      formData,
      flowConfig: flowConfigurations,
      licenseErrorCards,
      licenseId
    },
    handleNext,
    handleBack,
    handleChange
  };
};
